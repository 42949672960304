:root {
    --bg-color: rgb(20,20,20);
    --card-color: rgb(23,23,23);
}

.project-cards-container {
    
    align-items: center;
   
    
    display: flex;
    height: 40rem;
    justify-content: center;
    margin: 0px;
    overflow: hidden;
    padding: 0px;
}

#project-cards {
    // spaces cards into 2 rows of 3
    max-width: 1000px;
    width: calc(100% - 20px);
    display: flex;
    flex-wrap: wrap;
    gap: 8px;  
    justify-content: center;
}
// changes opacity for all divs borders within glows radius
#project-cards:hover > .project-in-the-way > .project-card > .project-card-border {
    opacity: 1;
}

.project-card {
    background-color: rgba(255,255,255,.1);
    border-radius: 10px;
    cursor: pointer;
    height: 260px;
    position: relative;
    width: 300px;
    padding-top: 2px;
    padding-left: 2px;
}

.project-card:hover::before {
    opacity: 1;
}

.project-card::before,
.project-card > .project-card-border {
    
    border-radius: inherit;
    content: '';
    height: 100%;
    left: 0px;
    opacity: 0;
    position: absolute;
    top: 0px;
    transition: opacity 500ms;
    width: 100%;
  
}

.project-card::before {
    // glow effect
    background: radial-gradient(
                        /* sets glow effect at mouse location */
        3000px circle at var(--mouse-x) var(--mouse-y),
        #cbb2717a,
        transparent 10%
    );
    z-index: 3;
}

.project-card > .project-card-border {
    background: radial-gradient(
        1500px circle at var(--mouse-x) var(--mouse-y),
        var(--clr-secondary-2),
        transparent 10%
    );
    z-index: 1;
}

.project-card > .project-card-content {
    // creates a border to apply the glow effect to
    background-color: var(--card-color);
    border-radius: inherit;
    height: calc(100% - 2px);
    
    position: relative;
    width: calc(100% - 2px);
    z-index: 2;
}

.project-card-content {
    display: flex;
    justify-content: center;
    
}

.project-card-content p {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.667);
    justify-content: center;
    z-index: 1;
    top: 1rem;
    padding: .5rem;
}

.project-card-content img {
    
    height: 100%;
    width: 100%;
    object-fit: cover;
    overflow: hidden;
    border-radius: inherit;
}

.project-card-content > img {
    opacity: .5;
}

@media screen and (max-width: 501px) {
    
.project-cards-container {
    
    height: 80rem;
    
}
}

@media screen and (max-width: 1000px) {
    .project-card {
        
        height: 200px;
        
        width: 240px;
        
    }
}