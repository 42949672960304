.nav-container {
    position: fixed; /* Set the navbar to fixed position */
    top: 0; /* Position the navbar at the top of the page */
    width: 100%; /* Full width */
    
    z-index: 10;
    
}

.nav {
    display: flex;
    padding-top: 2rem;
    justify-content: center;
    
    position: relative;
    height: 15rem;
    width: 100%;
    top: 0;
    isolation: isolate;
    -webkit-mask-image: url('../../images/nav.svg');
    mask-image: url('../../images/nav.svg');
    background-size: auto;
    background-image: linear-gradient(
        175deg, 
        rgba(203,178,113,1)0%, 
        var(--clr-black) 70%, 
      
    );
    
}

.nav-center {
    width: 60rem;
    max-width: var(--max-width);
    position: relative;
    z-index: -2;
}

.nav-header {
    padding: 0 5%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.nav-header h1 {
    color: white;
    text-shadow: var(--halo-yellow);
    font-family: var(--font-secondary);
    
    line-height: 2rem;
    text-underline-offset: .5rem;
}

.btn {
    font-size: 1rem;
    padding: 0.25rem 0.75rem;
    border-radius: var(--radius);
    border-color: transparent;
    color: white;
    background: var(--clr-black);
    
    transition: var(--transition);
}

.btn:hover {
    background: var(--clr-grey-5);
    
}

.nav-links {
    display: none;
}

.class:hover {
  
  cursor: pointer;
}
  
.nav::before {
    content: '';
}

/* nav media query */
@media screen and (min-width: 900px) {
  .nav-center {
    display: grid;
    grid-template-columns: auto 1fr auto;
    align-items: center;
    margin-top: -7rem;
  }
  .nav-header {
    padding: 0 0;
  }
  .toggle-btn {
    display: none;
  }
  
  .nav-links {
    display: block;
    justify-self: center;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    text-align: center;
    
    display: grid;
    align-items: center;
  }
  .nav-links li {
    height: 100%;
  }
  .link-btn {
    height: 7rem;
    background: transparent;
    border-color: transparent;
    font-size: 1.1rem;
    color: white;
    text-transform: capitalize;
    letter-spacing: 1px;
    width: 10rem;
  }
}

@-moz-document url-prefix() {
  .nav {
    
    -webkit-mask-image: url('../../images/ff-nav.svg');
    mask-image: url('../../images/ff-nav.svg');
    
    background-image: linear-gradient(
        180deg, 
        rgba(203,178,113,1)0%, 
        var(--clr-black) 100%, 
      
    );
    
}
    
}