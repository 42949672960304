.about-cards-modal-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: grid;
    place-items: center;
    visibility: hidden;
    z-index: 11;
    transition: var(--transition);
    transform: scale(0);
    background: rgba(0, 0, 0, 0.5);
}
.about-cards-modal {
    width: 90vw;
    height: 95vh;
    max-width: var(--fixed-width);
    background: var(--clr-white);
    border-radius: var(--radius);
    box-shadow: var(--dark-shadow);
    position: relative;
    padding: 4rem 2rem;
    
}
.about-cards-modal-container.show {
    visibility: visible;
    z-index: 11;
    transform: scale(1);
}
.close-btn {
    font-size: 2rem;
    background: transparent;
    border-color: transparent;
    color: var(--clr-grey-5);
    position: absolute;
    top: 1rem;
    right: 1rem;
    cursor: pointer;
  }