.contact-page {

    
    display: flex;
    width: 100%;
    height: 80rem;
    background-color: var(--clr-black);
    

    
    margin: 0 auto;
}

h1 {
    color: var(--clr-secondary-2);
}

p {
    color: var(--clr-white);
}


  
  .map-wrap {
    background: var(--clr-grey);
    float: right;
    width: 53%;
    height: 110%;
    
  }
  
  .leaflet-container {
    position: relative;
    width: 100%;
    height: 100%;
    opacity: 0;
    animation: backInRight 1s 1.2s;
    animation-fill-mode: forwards;
  }
  
  .info-map {
    position: absolute;
    background: var(--clr-grey);
    top: 15rem;
    margin-left: 2%;
    z-index: 1;
    width: 267px;
    padding: 20px;
    color: #fff;
    font-family: 'Helvetica';
    font-size: 17px;
    font-weight: 300;
    opacity: 0;
    animation: fadeIn 1s 1.5s;
    animation-fill-mode: forwards;
  
    span {
      font-size: 16px;
      color: var(--clr-secondary-2);
      
    }
    
  }

  .about-links {
    display: flex;
  }
  


  .footer {
    color: white;
    grid-area: 5 / 1 / 6 / 6;
    
    position: relative;
    width: 100%;
    height: 20rem;
    
    
    text-align: center;
    z-index: 10;
  }
  
/*   @media screen and (max-width: 1200px) {
    .map-wrap {
      float: none;
      margin: 0;
      width: 100%;
      height: 400px;
    }
    
    .info-map {
      display: none;
    }
  } */

@-moz-document url-prefix() {
  .info-map {
    top: 28%;
  }

  .leaflet-container {
    position: relative;
    top: 8%;
    width: 100%;
    height: 100%;
    opacity: 0;
    animation: backInRight 1s 1.2s;
    animation-fill-mode: forwards;
  }
}