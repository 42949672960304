.projects-page {
    margin-top: 7rem;
    padding-top: 10rem;
    display: inline-block;
    width: 100%;
    height: 100%;

   
    text-align: center;
    align-items: center;
    position: relative;
}

.git-calender {
    display: flex;
    justify-content: center;
    
}

.github {
    color: var(--clr-secondary-2);
    width: 100%;
    padding: 0 2rem;
}

.github a {
    color: var(--clr-white);
    text-shadow: var(--halo-white);
    display: flex;
    justify-content: center;
    align-items: center;
}   

.github p {
    padding-left: .5rem;
    font-weight: 500;
    font-size: 1.1rem;
    letter-spacing: .05rem;
}

.joke {
    padding: 5rem 0;
}

.flat-button {
    color: var(--clr-secondary-2);
    font-size: 11px;
    letter-spacing: 3px;
    text-decoration: none;
    padding: 8px 10px;
    border: 1px solid var(--clr-secondary-2);
   
    border-radius: 4px;
    background: 0 0;
    text-transform: uppercase;
    
    text-align: center;
    margin-top: 1rem;
    margin-right: 10px;
   
    cursor: pointer;
  }





@-moz-document url-prefix() {
    .projects-page {
        margin-top: 12rem;
        
    }
}