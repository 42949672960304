.about-card-groups,
.about-card-group,
.big-about-card {
    width: 15rem;
    height: 20rem;
}

.about-card-group {
    position: absolute;
    transition: transform 400ms ease;
}

.about-card-group[data-status="unknown"] {
    transform: scale(0);
}

.about-card-group[data-status="after"] {
    transform: translateX(50%) scale(0);
}

.about-card-group[data-status="becoming-active-from-before"] {
    transform: translateX(-50%) scale(0);
    transition: none;
}
.about-card-group[data-status="before"] {
    transform: translateX(-50%) scale(0);
}

.about-card-group[data-status="becoming-active-from-after"] {
    transform: translateX(50%) scale(0);
    transition: none;
}

.about-card {
    width: 15rem;
    height: 20rem;
    display: flex;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.5);
    padding: .1rem;
    position: absolute;
    transition: transform 800ms cubic-bezier(.05,.43,.25,.95);
}

.big-about-card {
    width: 15rem;
    height: 20rem;
    border-radius: 1.2rem;
    position: absolute;
}

.big-about-card h1 {
    position: absolute;
    top: 0;
    width: 14.8rem;
    font-size: 1.5rem;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.659);
    margin: .1rem .1rem .1rem .1rem;
    padding: .5rem 0;
    border-radius: 1.2rem;
}

.little-about-card {

    width: 7rem;
    height: 9rem;
    border-radius: 1.2rem;
    position: absolute;
    //centers
    left: 50%;
    margin-top: 10rem;
    transform: translate(-50%, -50%);
    
}

.little-about-card h1 {
    position: absolute;
    top: 0;
    width: 6.8rem;
    font-size: 1.5rem;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.659);
    margin: .1rem .1rem .1rem .1rem;
    padding: .5rem 0;
    border-radius: 1.2rem;
}

.about-card img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    overflow: hidden;
    border-radius: 1.2rem;
    padding: .1rem;
}
.about-card h1 {
    position: absolute;
    
}

.big-about-card:nth-child(2) {
    transform: translateX(-10%) rotate(-1deg);
}

.big-about-card:nth-child(4) {
    transform: rotate(2deg);
}

.big-about-card:nth-child(6) {
    transform: translateX(-6%) rotate(-3deg);
}

.big-about-card:nth-child(8) {
    transform: translateX(10%) translateY(3%) rotate(5deg);
}

// big cards hover
.about-card-group:hover > .big-about-card:nth-child(2) {
    transform: translate(-75%, 16%) rotate(-24deg);
}

.about-card-group:hover > .big-about-card:nth-child(4) {
    transform: translate(-20%, 8%) rotate(-8deg);
}

.about-card-group:hover > .big-about-card:nth-child(6) {
    transform: translate(25%, 8%) rotate(8deg);
}

.about-card-group:hover > .big-about-card:nth-child(8) {
    transform: translate(75%, 16%) rotate(24deg);
}

// little cards hover
.about-card-group:hover > .little-about-card:nth-child(1) {
    transform: translate(200%, -150%) rotate(19deg);
}

.about-card-group:hover > .little-about-card:nth-child(3) {
    transform: translate(160%, 150%) rotate(15deg);
}

.about-card-group:hover > .little-about-card:nth-child(5) {
    transform: translate(-200%, -160%) rotate(-15deg);
}

.about-card-group:hover > .little-about-card:nth-child(7) {
    transform: translate(-280%, 140%) rotate(-20deg);
}

.about-card-swiper-buttons {
    margin-top: 4rem;
    display: flex;
    justify-content: space-around;
}

.about-card-swiper-buttons > button { 
    font-size: 3rem;
    border: 0 solid rgb(200, 200, 200);
    background-color: transparent;
    cursor: pointer;
    color: var(--clr-white);
    filter: drop-shadow(var(--halo-yellow));
}

@media screen and (min-width: 350px) {

    .about-card-groups,
    .about-card-group,
    .big-about-card {
        width: 8rem;
        height: 12rem;
    }

    .about-card {
        width: 8rem;
        height: 12rem;
    }
    
    .big-about-card {
        width: 8rem;
        height: 12rem;        
    }
    
    .big-about-card h1 {
        width: 7.8rem;
        font-size: 1rem;
    }
    
    .little-about-card {
        width: 5rem;
        height: 7rem;
        margin-top: 5rem;
    }

    .little-about-card h1 {
        width: 4.8rem;
        font-size: .6rem;
        
    }

    // little cards hover
    .about-card-group:hover > .little-about-card:nth-child(1) {
        transform: translate(100%, -140%) rotate(19deg);
    }

    .about-card-group:hover > .little-about-card:nth-child(3) {
        transform: translate(110%, 130%) rotate(15deg);
    }

    .about-card-group:hover > .little-about-card:nth-child(5) {
        transform: translate(-200%, -139%) rotate(-15deg);
    }

    .about-card-group:hover > .little-about-card:nth-child(7) {
        transform: translate(-210%, 120%) rotate(-20deg);
    }
}

@media screen and (min-width: 650px) {

    .about-card-groups,
    .about-card-group,
    .big-about-card {
        width: 10rem;
        height: 13.5rem;
    }

    .about-card {
        width: 10rem;
        height: 13.5rem;
    }
    
    .big-about-card {
        width: 10rem;
        height: 13.5rem;      
    }

    .big-about-card h1 {
        width: 9.8rem;
        font-size: 1rem;
    }
    
    
    .little-about-card {
        width: 6rem;
        height: 8rem;
        margin-top: 5rem;
    }

    .little-about-card h1 {
        width: 5.8rem;
        font-size: .8rem;
        
    }

    // little cards hover
    .about-card-group:hover > .little-about-card:nth-child(1) {
        transform: translate(100%, -130%) rotate(19deg);
    }

    .about-card-group:hover > .little-about-card:nth-child(3) {
        transform: translate(110%, 130%) rotate(15deg);
    }

    .about-card-group:hover > .little-about-card:nth-child(5) {
        transform: translate(-200%, -139%) rotate(-15deg);
    }

    .about-card-group:hover > .little-about-card:nth-child(7) {
        transform: translate(-210%, 120%) rotate(-20deg);
    }
}   

@media screen and (min-width: 800px) {
    .about-card-groups,
    .about-card-group,
    .big-about-card {
        width: 15rem;
        height: 20rem;
    }

    .about-card {
        width: 15rem;
        height: 20rem;
    }
    
    .big-about-card {
        width: 15rem;
        height: 20rem;
    }
    
    .big-about-card h1 {
        width: 14.8rem;
        font-size: 1.5rem;
    }
    
    .little-about-card {
        width: 7rem;
        height: 9rem;
        margin-top: 10rem;
    }

    .little-about-card h1 {
        width: 6.8rem;
        font-size: 1rem;
        
    }
    
    

        // little cards hover
    .about-card-group:hover > .little-about-card:nth-child(1) {
        transform: translate(200%, -150%) rotate(19deg);
    }

    .about-card-group:hover > .little-about-card:nth-child(3) {
        transform: translate(160%, 150%) rotate(15deg);
    }

    .about-card-group:hover > .little-about-card:nth-child(5) {
        transform: translate(-200%, -160%) rotate(-15deg);
    }

    .about-card-group:hover > .little-about-card:nth-child(7) {
        transform: translate(-280%, 140%) rotate(-20deg);
    }
}
