.email-form {
    align-self: center;
    background-color: var(--clr-grey);
    width: 70%;
    padding: 2rem;
    margin: 2rem;
}

.contact-form {
    width: 100%;
    margin-top: 20px;
    
  
    ul {
      padding: 0;
      margin: 0;
  
      li {
        padding: 0;
        margin: 0;
        list-style: none;
        margin-bottom: 10px;
        opacity: 0;
        overflow: hidden;
        display: block;
        clear: both;
        position: relative;
        animation: fadeInUp 2s 2s;
        animation-fill-mode: forwards;
      }
  
      li.half {
        width: 49%;
        margin-left: 2%;
        float: left;
        clear: none;
  
        &:first-child {
          margin-left: 0;
        }
      }
    }
  
    input[type='text'],
    input[type='email'] {
      width: 100%;
      border: 0;
      background: var(--clr-secondary-2);
      height: 50px;
      font-size: 16px;
      color: #fff;
      padding: 0 20px;
      
    }
  
   textarea {
      width: 100%;
      border: 0;
      background: var(--clr-secondary-2);
      height: 50px;
      font-size: 16px;
      color: #fff;
      padding: 20px;
      
      min-height: 150px;
    }
  
    .flat-button {
      color: var(--clr-secondary-2);
      font-size: 11px;
      letter-spacing: 3px;
      text-decoration: none;
      padding: 8px 10px;
      border: 1px solid var(--clr-secondary-2);
      float: left;
      border-radius: 4px;
      background: 0 0;
      text-transform: uppercase;
      float: right;
      text-align: center;
      margin-right: 10px;
    }
  }