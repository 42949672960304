.home-page {
    
    height: 10rem;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(5, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
}



.content {
    grid-area: 1 / 1 / 5 / 6;
    margin-top: 7rem;
    height: 79rem;
    width: 100%;
    max-height: 100%;
    background-image: url(../../images/forest.jpg);
    background-size: cover;
    
    background-repeat: no-repeat;
    z-index: 1;
    
}



.hero {
    position: relative;
    min-height: 60em;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;
}

.hero-center {
    height: 90%;
    width: 100%;
    margin-top: 9rem;
    max-width: var(--max-width);
    display: flex;
    align-items: center;
    justify-content: center;
    
    background-color: rgba(0, 0, 0, 0.451);
    border-radius: 10rem;
}

.hero-info {
    width: 100%;
    height: 100%;
    
    text-align: center;
    justify-content: center;
    padding: 2rem 5rem 0 5rem;
}

.hero-img {
    margin-bottom: .5rem;
    height: 15rem;
    border-radius: 50%;
    box-shadow: var(--halo-yellow);
}

.hero-text {
    padding-top: 1rem;
    color: rgba(255, 255, 255, 0.844);
    background-color: rgba(0, 0, 0, 0.574);
    border-radius: 1rem;
    padding: 1rem;
}

.contact-me {
    padding-left: 6px;
}
  
.contact-me .text-animate-hover {
    margin: -.5px;
    
}

@media screen and (min-width: 250px) {
    .hero-info {
        padding: 2rem .5rem 0 .5rem;
    }
    
    .hero-center {
        width: 100%;
        
    }
    
    .name {
        font-size: 1.2rem;
    }
    
    .hero-img {
        height: 7rem;
        
    }
}

@media screen and (min-width: 500px) {
    .hero-info {
        padding: 2rem 1rem 0 1rem;
    }
    
    .hero-center {
        width: 95%;
        
    }

    .name {
        font-size: 2rem;
    }
    
    .hero-img {
        height: 10rem;
        
    }
}

@media screen and (min-width: 550px) {
    .hero-info {
        padding: 2rem 2rem 0 2rem;
    }
    
    .hero-center {
        width: 90%;
        
    }
    
    
    
    
    
    
    

    .name {
        font-size: 2rem;
    }
    
    .hero-img {
        height: 10rem;
        
    }
    
}

@media screen and (min-width: 950px) {
    .hero-info {
        padding: 2rem 5rem 0 5rem;
    }
    
    .hero-center {
        width: 100%;
        
    }
    .name {
        font-size: 4rem;
    }
    .hero-img {
        height: 15rem;
        
    }
}

@-moz-document url-prefix() {
    .home-page {
        padding-top: 10rem;
        
        height: 100%;
        
    }
}