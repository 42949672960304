.submenu-container {
    position: fixed; /* Set the navbar to fixed position */
    top: 0; /* Position the navbar at the top of the page */
    z-index: 11;
  }
  
  .submenu {
    background: var(--clr-white);
    box-shadow: var(--dark-shadow);
    position: absolute;
    top: 4rem;
    left: 50%;
    transform: translateX(-50%);
    
    display: none;
    padding: 1.5rem;
    border-radius: var(--radius);
    transition: var(--transition);
  }
  .submenu::before {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-left: 2rem solid transparent;
    border-right: 2rem solid transparent;
    border-bottom: 2rem solid var(--clr-white);
    position: absolute;
    top: -2rem;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .submenu.show {
    display: block;
    
  }
  
  .submenu-center {
    display: grid;
    gap: 0.25rem 2rem;
  }
  .col-2 {
    grid-template-columns: repeat(2, 1fr);
  }
  .col-3 {
    grid-template-columns: repeat(3, 1fr);
  }
  .col-4 {
    grid-template-columns: repeat(4, 1fr);
  }
  .submenu h4 {
    margin-bottom: 1.5rem;
  }
  .submenu-center p {
    width: 10rem;
    display: block;
    
    text-transform: capitalize;
    display: flex;
    align-items: center;
  }
  .submenu-center svg {
    color: var(--clr-black);
    filter: drop-shadow(var(--halo-yellow));
   
    font-family: var(--font-secondary);
    margin-right: 1rem;
  }
  .submenu .link-text p{
    color: var(--clr-black);
    text-shadow: var(--halo-yellow);
  }
  