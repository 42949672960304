/* sidebar */
.nav-modal-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: grid;
    place-items: center;
    visibility: hidden;
    z-index: 11;
    transition: var(--transition);
    transform: scale(0);
    background: rgba(0, 0, 0, 0.5);
    
  }
  .nav-modal-wrapper.show {
    visibility: visible;
    z-index: 11;
    transform: scale(1);
  }
  .nav-modal {
    width: 90vw;
    height: 95vh;
    max-width: var(--fixed-width);
    background: var(--clr-white);
    border-radius: var(--radius);
    box-shadow: var(--dark-shadow);
    position: relative;
    padding: 4rem 2rem;
    
  }
  .close-btn {
    font-size: 2rem;
    background: transparent;
    border-color: transparent;
    color: var(--clr-grey-5);
    position: absolute;
    top: 1rem;
    right: 1rem;
    cursor: pointer;
  }
  .nav-modal article {
    margin-bottom: 2rem;
    
    
  }
  .nav-modal-sublinks {
    display: inline-block;
    
    row-gap: 0.25rem;
    padding: 0 0 .5rem;
  }
  .nav-modal-sublinks a {
    
    
    text-transform: capitalize;
    padding: 0 1rem;
    align-items: center;
  }
  .nav-modal-sublinks svg {
    color: var(--clr-grey-5);
    margin-right: 1rem;
  }
  
  @media screen and (min-width: 900px) {
    .nav-modal-wrapper {
      display: none;
    }
  }